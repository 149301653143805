// src/App.js

import React from 'react';
import ChatbotContainer from './ChatbotContainer';

function App() {
  return (
    <div className="App">
      <ChatbotContainer />
    </div>
  );
}

export default App;

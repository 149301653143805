import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import './ChatbotContainer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Button } from 'antd';
import { Input } from 'antd';
import {Card} from 'antd';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ChatbotContainer = () => {
  const [state, setState] = useState({
    response: null,
    pdfLink: 'pdfs/trustbridge.pdf',
    input: '',
    displayInput: '',
    processing: false,
    pageNumber: 11,
  });

  const pdfViewerRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const sendQuestionToBackend = async (question) => {
    setState((prevState) => ({
      ...prevState,
      processing: true,
      displayInput: question,
    }));

    try {
      const response = await axios.post('https://ins08.truliacare.com/ask', {
        question,
      });
      setState((prevState) => ({
        ...prevState,
        response: response.data,
        pageNumber: response.data.page_number || 1,
        input: '',
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setState((prevState) => ({ ...prevState, processing: false }));
    }
  };

  const renderResponse = () => {
    if (!state.response || !state.response.Response) {
      return null;
    }

    const guidelines = state.response.Response.split('\n');

    return (
      <Card
        title="Response"
        className="response-card"
        headStyle={{ color: '#007bff', fontWeight: '300', fontSize: '20px' }}
      >
        <ul>
          {guidelines.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </Card>
    );
  };

  const handleSuggestionClick = (question) => {
    sendQuestionToBackend(question);
    setIsModalVisible(false);
  };

  const renderSuggestions = () => {
    const suggestedQuestions = [
    "How to manage pain",
     "My mother is experiencing gastrointestinal symptoms. How can you help her?",
     "My grandfather has a high fever. How can you manage his comfort?",
     "My cousin is dealing with nausea and vomiting. Give me personalised care information?",
     "My spouse is in pain. How do I assess their pain",
     "My friend is having respiratory challenges. Provide me a personalised support ?",
     "My wife has some wounds and skin changes. Provide me specialised care plans?",
     "How to manage nausea and vomiting?",
     "My uncle's going through some agitation. Any ideas on how I can help him feel calm?",
     "My grandmother is facing bladder symptoms. How can she be helped?",
     "How to give my mother the Morphine Oral Syringe?",
    ];

    return (
      <Modal
        title="Suggested Questions"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <ul className="suggested-questions">
          {suggestedQuestions.map((question, index) => (
            <li key={index} style={{ cursor: "pointer" }} onClick={() => handleSuggestionClick(question)}>
              {question}
            </li>
          ))}
        </ul>
      </Modal>
    );
  };
  return (
    <div className="chatbot-container">
      <div className="chatbot-section">
        <h3 style={{fontWeight:"300"}}>Insights Chatbot</h3>
        <div className="question-form-container">
        <Input
            id="questionInput"
            type="text"
            value={state.displayInput}
            onChange={(e) =>
              setState((prevState) => ({
                ...prevState,
                input: e.target.value,
                displayInput: e.target.value,
              }))
            }
            placeholder="Type your question here..."
            onPressEnter={() => sendQuestionToBackend(state.input)}
          />
          <span
            className="info-icon"
            onClick={() => setIsModalVisible(!isModalVisible)}
          >
            <InfoCircleOutlined style={{ fontSize: '24px', color: '#007bff' }} />
          </span>
          <Button
  type="primary"
  onClick={() => sendQuestionToBackend(state.input)}
  disabled={state.processing}
>
  {state.processing ? 'Processing...' : 'Send'}
</Button>
        </div>
        {renderSuggestions()}
        {state.response && (
          <div>{renderResponse()}</div>
        )}
      </div>

      <div className="pdf-viewer-section">
        {state.pdfLink && (
          <Document
            file={state.pdfLink}
            onLoadSuccess={() => console.log('Document loaded')}
          >
            <Page
              pageNumber={state.pageNumber}
              onLoadSuccess={() => console.log('Page loaded')}
              ref={pdfViewerRef}
            />
          </Document>
        )}
      </div>
    </div>
  );
};

export default ChatbotContainer;
